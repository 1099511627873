import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import CardArticle from "./cards/cardArticle";
import Button from "./Button";
import PlusIcon from "../images/plus-icon.svg";

import articleImg from "../images/article-demo-img.jpg";
import "./all-blog-posts.scss";
import Tabs from "./filterTab/tabs";
import Tab from "./filterTab/tab";
import TabPanel from "./filterTab/tab-panel";
import Select from "./custom/select";
import Option from "./custom/select/option";
import { graphql, useStaticQuery } from "gatsby";

function AllBlogPosts() {
  const data = useStaticQuery(graphql`
    query {
      allNewsData {
        nodes {
          slug
          author
          title
          date
          type
          link
        }
        totalCount
      }
    }
  `);
  const allNews = data.allNewsData.nodes;

  const [tab, setTab] = useState("press-releases");
  const [visiblePost, setVisiblePosts] = useState(4);
  const [dateSelected, setDateSelected] = useState("");

  // Load more button click
  const handleLoadMoreClick = () => {
    setVisiblePosts(visiblePost + 4);
  };
  const handleDateChange = (date) => {
    setVisiblePosts(4);
    setDateSelected(date);
  };
  const handleTabChange = (e, tab) => {
    setVisiblePosts(4);
    setTab(tab);
  };

  let filteredPosts = allNews.filter((item) => {
    let dateMatched =
      dateSelected === new Date(item.date).getFullYear().toString();
    if (!dateSelected) {
      dateMatched = true;
    }
    return item.type === tab && dateMatched;
  });

  filteredPosts =
    tab !== "articles"
      ? filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date))
      : filteredPosts;

  const slicedPosts = filteredPosts.slice(0, visiblePost);

  return (
    <div className="all-blog-posts">
      <div className="filter-tabs__wrap">
        <Select
          value={dateSelected}
          className="select-date"
          onChange={handleDateChange}
        >
          <Option value={""} selected>
            Filter By Date
          </Option>
          <Option value={"2022"}>2022</Option>
          <Option value={"2023"}>2023</Option>
          <Option value={"2024"}>2024</Option>
        </Select>
        <Tabs onChange={handleTabChange} value={tab}>
          <Tab label="Press Releases" value="press-releases" />
          <Tab label="Articles" value="articles" />
          <Tab label="Publications" value="publications" />
          <Tab label="Videos" value="videos" />
          <Tab label="Intellectual Property" value="intellectual-property" />
        </Tabs>
      </div>
      <TabPanel index={"press-releases"} value={tab}>
        <Row className="row">
          {slicedPosts.map((item, index) => (
            <Col key={index} lg={6} className="col">
              <CardArticle
                authorName={item.author}
                articleTitle={item.title}
                articleDate={item.date}
                thumbnailImg={articleImg}
                newTab={item.slug}
                link={item.slug ? `/${item.slug}` : item.link}
              />
            </Col>
          ))}
        </Row>
      </TabPanel>
      <TabPanel index={"articles"} value={tab}>
        <Row className="row">
          {slicedPosts.map((item, index) => (
            <Col key={index} lg={6} className="col">
              <CardArticle
                authorName={item.author}
                articleTitle={item.title}
                articleDate={item.date}
                newTab={item.slug}
                thumbnailImg={articleImg}
                link={item.slug ? `/${item.slug}` : item.link}
              />
            </Col>
          ))}
        </Row>
      </TabPanel>
      <TabPanel index={"publications"} value={tab}>
        <Row className="row">
          {slicedPosts.map((item, index) => (
            <Col key={index} lg={6} className="col">
              <CardArticle
                authorName={item.author}
                articleTitle={item.title}
                articleDate={item.date}
                newTab={item.slug}
                thumbnailImg={articleImg}
                link={item.slug ? `/${item.slug}` : item.link}
              />
            </Col>
          ))}
        </Row>
      </TabPanel>
      <TabPanel index={"videos"} value={tab}>
        <Row className="row">
          {slicedPosts.map((item, index) => (
            <Col key={index} lg={6} className="col">
              <CardArticle
                authorName={item.author}
                articleTitle={item.title}
                articleDate={item.date}
                newTab={item.slug}
                thumbnailImg={articleImg}
                link={item.slug ? `/${item.slug}` : item.link}
              />
            </Col>
          ))}
        </Row>
      </TabPanel>
      <TabPanel index={"intellectual-property"} value={tab}>
        <Row className="row">
          {slicedPosts.map((item, index) => (
            <Col key={index} lg={6} className="col">
              <CardArticle
                authorName={item.author}
                articleTitle={item.title}
                articleDate={item.date}
                newTab={item.slug}
                thumbnailImg={articleImg}
                link={item.slug ? `/${item.slug}` : item.link}
              />
            </Col>
          ))}
        </Row>
      </TabPanel>

      {visiblePost < filteredPosts.length && (
        <div className="btn-wrap">
          <Button
            className="btn btn--alt text-white"
            btnTitle="Load More"
            btnIcon={PlusIcon}
            onClick={handleLoadMoreClick}
          />
        </div>
      )}
    </div>
  );
}

export default AllBlogPosts;
