import React from "react";
import "./cardArticle.scss";
import { Col, Row } from "react-grid-system";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function CardArticle(props) {
  return (
    <Popup
      trigger={
        <a
          href={props.link}
          className="popup-block"
          target="_blank"
          rel="noreferrer"
        >
          <div className="card-article">
            <Row className="row">
              {/* <Col xl={6.5} lg={6}>
                        <div className="card-article__media">
                            <img src={props.thumbnailImg} alt={props.articleTitle} />
                        </div>
                    </Col> */}
              <Col lg={12} className="col">
                <div className="card-article__content">
                  {/*  <p><strong>{props.articleTitle}</strong></p> */}
                  <p
                    dangerouslySetInnerHTML={{ __html: props.articleTitle }}
                    className="strong"
                  ></p>
                  {props.articleDate && (
                    <span className="date">
                      <em>{props.authorName}</em> | {props.articleDate}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </a>
      }
      modal
      disabled={props.link}
    >
      {(close) => (
        <div className="news-popup">
          <div className="modal-header text-right">
            <button className="close" onClick={close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.456"
                height="25.456"
                viewBox="0 0 25.456 25.456"
              >
                <g
                  id="icon-popup-close"
                  transform="translate(-996.772 -249.772)"
                >
                  <line
                    id="Line_3590"
                    data-name="Line 3590"
                    x2="30"
                    transform="translate(998.894 251.894) rotate(45)"
                    fill="none"
                    stroke="#a84192"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                  <line
                    id="Line_3591"
                    data-name="Line 3591"
                    x2="30"
                    transform="translate(1020.107 251.894) rotate(135)"
                    fill="none"
                    stroke="#a84192"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: props.content }}
            className="news-popup__content"
          />
        </div>
      )}
    </Popup>
  );
}

export default CardArticle;
